import { UploadFileApi } from '@/api/public'
import axios from 'axios'

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const get_suffix = (filename) => {
  const pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}
export const random_string = (len) => {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 通过policy协议
*/

export const policy = () => {
  const pro = new Promise((resolve, rej) => {
    // 判断签名有没有过期
    var res = JSON.parse(localStorage.getItem('sign'))
    var timestamp = Date.parse(new Date()) / 1000
    if (res && res.expire - 3 > timestamp) {
      resolve(res)
    } else {
      UploadFileApi().then(res => {
        console.log('UploadFileApi2', res)
        if (res.code === 0) {
          localStorage.setItem('sign', JSON.stringify(res.data))
          resolve(res.data)
        }
      })
    }
  })
  return pro
}

/**
 * 上传文件到oss
*/
export function upProToOss(file) {
  const pro = new Promise((resolve, rej) => {
    // 判断签名有没有过期
    var res = JSON.parse(localStorage.getItem('sign'))
    var timestamp = Date.parse(new Date()) / 1000
    if (res && res.expire - 3 > timestamp) {
      resolve(res)
    } else {
      UploadFileApi().then(res => {
        console.log('UploadFileApi1', res)
        if (res.code === 0) {
          localStorage.setItem('sign', JSON.stringify(res.data))
          resolve(res.data)
        }
      })
    }
  })
  const imglist = new Promise((resolve, rej) => {
    pro.then(success => {
      var data = success
      var ossData = new FormData()
      ossData.append('name', file.file.name)
      // key就代表文件层级和阿里云上的文件名
      var filename = file.file.name
      const picName = random_string(10) + get_suffix(filename)
      const keyValue = data.dir + picName
      console.log('picName', picName)
      console.log('picName', keyValue)
      ossData.append('key', keyValue)
      ossData.append('policy', data.policy)
      ossData.append('OSSAccessKeyId', data.accessid)
      ossData.append('success_action_status', 200)
      ossData.append('signature', data.signature)
      ossData.append('file', file.file, file.file.name)
      console.log('ossData', ossData)
      axios.post(data.host, ossData).then((response) => {
        const url = data.host + '/' + keyValue
        console.log('uploadImage success is:', url)
        resolve(url)
      }).catch((err) => {
        console.log(err)
      })
    })
  })
  return imglist
}
