<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div class="back_top_name  flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name ">我要退款</div>
      <div />
    </div>
    <!-- 商品信息 -->
    <div class="return_pro_list">
      <div class="cart_company return_pro" style="margin-bottom:0">
        <div class="company_name flex-r-s-c" style="padding-top:0.3rem;padding-left: 0.4rem;color:#fff">
          <van-checkbox v-model="checkedAll" checked-color="#0767AB" class="returnCheck" style="height:1rem;color:#fff"
                        @click="checkAll"
          >选择退款商品</van-checkbox>
        </div>
        <div class="company_pro_list" style="padding-left: 0.4rem;margin-top:0.2rem">
          <van-checkbox-group ref="cartCheck" v-model="result" class="checkboxGroup" @change="checkGroup">
            <div v-for="(pro_item,pro_index) in cartProInfo.cartCompanies" :key="pro_index" class="flex-r-s-c">
              <van-checkbox :name="pro_index" checked-color="#0767AB" style="margin:0 0.2rem" />
              <van-swipe-cell>
                <proCard05 :info="pro_item" @changeNum="changeNum" />
              </van-swipe-cell>
              <van-divider />
            </div>
          </van-checkbox-group>
        </div>
      </div>
    </div>
    <!-- 选择退款原因 -->
    <div class="return_case_box">
      <div class="case_box_title">选择退款原因</div>
      <van-cell is-link title="" :value="form.refundWhy" @click="show = true">
        <template #title>
          <span class="custom-title">退款原因<span style="color:#ED0D0D;">*</span></span>
        </template>
      </van-cell>
      <!-- <van-divider />
      <div class="case_box_money">退款金额<span style="color:#ED0D0D;">*</span></div>
      <div class="case_box_money_tip">预计退款 ￥{{ orderInfo.orderPrice }}，未算入优惠活动，以实际审核金额为准。</div> -->
    </div>
    <!-- 补充描述和凭证 -->
    <div class="return_case_box">
      <div class="case_box_title">补充描述和凭证</div>
      <van-field v-model="form.whySupplement" rows="1" autosize type="textarea" placeholder="补充描述，有助于商家更好为您处理售后问题"
                 maxlength="200" show-word-limit
      />
      <van-divider />
      <div class="case_box_title">上传照片凭证 <span style="font-size: 0.4rem;color: #999999;">最多3张</span></div>
      <div class="case_box_upload">
        <van-uploader v-model="fileList01" :after-read="afterRead01" :max-count="3" upload-icon="plus" />
      </div>
    </div>
    <!-- 提价申请 -->
    <div class="return_sub flex-r-c-c">
      <van-button round block type="info" loading-text="提交中..." native-type="resubmit" color="#0767ab"
                  style="font-size: 0.43rem;margin-top:0.6rem;margin-bottom:1rem" class="next_btn " @click="sub"
      >确认信息
      </van-button>
    </div>
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect"
                      @cancel="onCancel"
    />
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" title="该商品涉及赠品" msg01="请联系客服退款退货" />
  </div>
</template>

<script>
import './index.scss'
import '../cart/index.scss'
import { upProToOss } from '@/utils/ali-oss'
import { mallOrder_buyer_detail, progressingProductRefund, refund_reason, refund_addBefore } from '@/api/order'
// import { mallOrder_buyer_detail, progressingProductRefund, refund_reason } from '@/api/order'
import kefuBtn from '@/components/kefu_btn'
import proCard05 from '@/components/pro_card_05'
export default {
  components: { kefuBtn, proCard05 },
  data() {
    return {
      orderId: '',
      cartProInfo: { cartCompanies: [], totalPrice: 0 },
      result: [],
      checkedAll: false,
      form: {},
      show: false,
      actions: [],
      fileList01: [],
      fileListcopy: [],
      choosed: [],
      orderInfo: {}
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.getCartList()
    this.getReasonList()
  },
  methods: {
    backPath() { window.history.back() },
    // 获取退款原因列表
    getReasonList() {
      refund_reason().then(res => {
        this.actions = res.data
      })
    },
    // 选择原因
    onSelect(item) {
      this.show = false
      this.$set(this.form, 'refundType', item.type)
      this.$set(this.form, 'refundWhy', item.name)
    },
    getCartList() {
      // 订单信息
      mallOrder_buyer_detail(this.orderId).then(res => {
        this.orderInfo = res.data
        progressingProductRefund(res.data.orderId).then(res => {
          for (const i in res.data) {
            this.$set(res.data[i], 'checked', true)
            this.$set(res.data[i], 'refundNum', res.data[i].remain)
            this.result.push(i)
          }
          this.$nextTick(() => {
            this.$refs.cartCheck.toggleAll(true)
          })
          this.cartProInfo.cartCompanies = res.data
          this.$forceUpdate()
        })
      })
    },
    // 单选
    checkGroup(val) {
      console.log('val', val)
      this.choosed = val
      if (val.length === this.cartProInfo.cartCompanies.length) { this.checkedAll = true } else { this.checkedAll = false }
    },
    // 修改数量
    changeNum(num) {
      console.log('num', num)
    },
    // 全选
    checkAll() {
      this.$refs.cartCheck.toggleAll(this.checkedAll)
    },
    onCancel() {
    },
    // 图片上传
    afterRead01(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      upProToOss(file).then(res => {
        this.fileListcopy.push(res)
        file.message = '上传成功'
        file.url = res
        file.status = 'done'
      }).catch(() => {
        setTimeout(() => {
          file.status = 'failed'
          file.message = '上传失败'
        }, 1000)
      })
    },
    // 提交
    sub() {
      // 凭证
      const picSupplements = []
      for (const i in this.fileList01) { picSupplements.push(this.fileList01[i].url) }
      this.$set(this.form, 'picSupplements', picSupplements)
      // 商品
      const refundProductRes = []
      // for (var n = 0; n < this.cartProInfo.cartCompanies.length; n++) {
      //   for (var m = 0; m < this.result.length; m++) {
      //     if (n === this.result[m]) {
      //       const parms = {
      //         orderDetailId: this.cartProInfo.cartCompanies[n].orderDetailId,
      //         refundNum: this.cartProInfo.cartCompanies[n].refundNum,
      //         refundPrice: this.cartProInfo.cartCompanies[n].proPrice
      //       }
      //       refundProductRes.push(parms)
      //     }
      //   }
      // }
      for (const i in this.choosed) {
        const parms = {
          orderDetailId: this.cartProInfo.cartCompanies[this.choosed[i]].orderDetailId,
          refundNum: this.cartProInfo.cartCompanies[this.choosed[i]].refundNum,
          refundPrice: this.cartProInfo.cartCompanies[this.choosed[i]].proPrice
        }
        refundProductRes.push(parms)
      }
      this.$set(this.form, 'refundProductRes', refundProductRes)
      this.$set(this.form, 'orderId', this.orderId)
      console.log(this.form)
      refund_addBefore(this.form).then(res => {
        if (res.code === 10166) { this.openKeFu() } else {
          sessionStorage.setItem('returnInfo', JSON.stringify(res.data))
          this.$router.push(`/order/orderReturnConfirm?orderId=${this.orderId}`)
        }
        // this.$toast({ message: '已提交', duration: 1 * 1000 })
        // this.backPath()
      })
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }

  }
}
</script>

<style lang="scss" >
.cart_company .company_name .van-icon {
  color: #ffffff !important;
  font-size: 0.8em;
}
.van-cell::after {
  border: none;
}
</style>
